<template>
  <section class="row t-getting-jobs-frame">
    <div class="col-12 px-md-4">
      <h2 class="h1">
        What our members say about Ivy Exec
      </h2>
    </div>
    <div class="col-12">
      <splide
        :slides="testemonials"
        :options="sliderOptions"
      >
        <div class="splide__arrows">
          <button class="splide__arrow splide__arrow--prev"> Prev </button>
          <button class="splide__arrow splide__arrow--next"> Next </button>
        </div>
        <splide-slide
          v-for="(testemonial, index) in testemonials"
          :key="index">
          <m-testemonial-card
            :testemonial="testemonial"
          />
        </splide-slide>

        <!-- Custom buttons -->
<!--        <template v-slot:controls>-->
<!--          <div class="splide__arrows">-->
<!--            <button class="splide__arrow splide__arrow&#45;&#45;prev">-->
<!--              <a-icon icon="arrow-left-lg" />-->
<!--            </button>-->
<!--            <button class="splide__arrow splide__arrow&#45;&#45;next">-->
<!--              <a-icon icon="arrow-right-lg" />-->
<!--            </button>-->
<!--          </div>-->
<!--        </template>-->
      </splide>
    </div>
  </section>
</template>

<script>
import { TESTEMONIALS } from "@/constants/testemonials";
import { TESTEMONIAL_SLIDER_OPTIONS } from "@/constants/splideOptions";
import MTestemonialCard from "@/components/molecules/MTestemonialCard";

export default {
  components: {
    MTestemonialCard,
  },
  data() {
    return {
      testemonials: TESTEMONIALS,
      sliderOptions: TESTEMONIAL_SLIDER_OPTIONS,
    }
  },
}
</script>
