<template>
  <span
    class="a-icon"
    v-html="SVG[icon]">
  </span>
</template>

<script>
import { SVG } from "@/constants/svgIcons";

export default {
  props: {
    icon: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      SVG,
    };
  },
}
</script>