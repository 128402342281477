<template>
  <div>
    <div class="m-blog-card card h-100">
      <div class="position-relative">
        <a :href="data.link" target="blank">
          <img :src="
            data['_embedded']['wp:featuredmedia'][0]['media_details'] ?
            data['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['medium']['source_url']
            : require(`@/assets/images/wp-backup-images/backup${index}.jpg`)"
               :alt="data['_embedded'] && data.title.rendered"
               class="card-img-top"
          >
        </a>
      </div>
      <div class="card-body">
        <div class="border-top-0 text-uppercase">
          <div class="mb-1 d-flex flex-wrap align-items-center">
            <span v-if="data['_embedded']" class="categories">
              {{ data['_embedded']['wp:term'][0][0].name }}
            </span>

<!--            <span-->
<!--              v-for="category in data['_embedded'] && data['_embedded']['wp:term'][0]"-->
<!--              :key="category.id"-->
<!--              class="categories"-->
<!--            >-->
<!--              {{ category.name }}-->
<!--            </span>-->
          </div>
        </div>

        <a :href="data.link" target="blank" class="text-decoration-none">
          <h5>{{ format(data['_embedded'] && data.title.rendered)  }}</h5>
        </a>

        <p class="card-description"
           v-html="data['_embedded'] && data['excerpt']['rendered']">
        </p>
      </div>

      <div class="card-footer bg-white border-top-0 pt-0 mb-2">
        <a
          :href="data.link"
          target="blank"
          class="learn-more"
        >
          <span class="me-2"> Read more </span>
          <a-icon icon="arrow-right" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: [Object, Array],
    index: String,
  },
  methods: {
    format(content) {
      return content.replace("&#038;", "&");
    }
  }
};
</script>
