export const IvyLog = (function() {
    return {
        l: function (message) {
            if (typeof message === Object) {
                console.log('%c IVYEXEC', message, 'background: #222; color: #fff; padding: 2px; margin: 2px;font-weight: bold');
            } else {
                console.log('%c IVYEXEC %c ' + message, 'background: #222; color: #fff; padding: 2px; margin: 2px;font-weight: bold', 'background: #222; color: #fff; padding: 2px; margin: 2px');
            }
        },

        logMessage: function(message) {
            console.log('%c IVYEXEC %c ' + message + ' ', 'background: #222; color: #fff; padding: 2px; margin: 2px;font-weight: bold', 'background: #737578; color: #fff; padding: 2px; margin: 2px');
        },

        logWarning: function(message) {
            console.log('%c IVYEXEC %c ' + message + ' ', 'background: #222; color: #fff; padding: 2px; margin: 2px;font-weight: bold', 'background: #ff9900; color: #000; padding: 2px; margin: 2px');
        },

        logError: function(message) {
            console.log('%c IVYEXEC %c ' + message + ' ', 'background: #222; color: #fff; padding: 2px; margin: 2px;font-weight: bold', 'background: #ff3300; color: #fff; padding: 2px; margin: 2px');
        },

        logSuccess: function(message) {
            console.log('%c IVYEXEC %c ' + message + ' ', 'background: #222; color: #fff; padding: 2px; margin: 2px;font-weight: bold', 'background: #33cc33; color: #fff; padding: 2px; margin: 2px');
        }
    }
}())
export const IvyPayment = (function() {

    var  showSuccessModal = true;
    var  renderMenuOnSuccess = true;
    var  paymentSuccess = false;
    var  opened = false;
    var  paymentModalOpened = new Event('ivy-payment-modal-opened');
    var  successModalOpened = new Event('ivy-success-modal-opened');
    var  paymentDone = new Event('payment-done');

    var billingInfo = {
        "1": {duration: '1 Month', price: 42, total: 42},
        "3": {duration: '3 Months', price: 35, total: 105},
        "6": {duration: '6 Months', price: 28, total: 166},
    };
    return {
        processPayment: function (type, data) {

            //ovde ce da procesuira
            IvyLog.logWarning('Processing payment...');
            return IvyRpc.Rpc('Payment\\Payment', 'processPayment', {type: type, data: data});
        },

        getAvailablePromotion: function () {
            var url = new URL(window.location.href);
            return IvyRpc.Rpc('Payment\\Payment', 'getAvailablePromotion', {promoFromUrl: url.searchParams.get("promo")});
        },

        checkPromoCode: function (promoCode) {
            return IvyRpc.Rpc('Payment\\Payment', 'checkPromo', {promoCode: promoCode});
        },

         getBillingStrings: function(index)
        {
            return billingInfo[index];
        },

    
    openUpgradeModal: function()
    {
        IvyModal.openModal('upgradeModal');
        window.dispatchEvent(paymentModalOpened);
        opened = 'upgradeModal';
        IvyLog.l('Payment Modal Opened');
    },

    
    openSuccessModal: function()
    {
        $('body').addClass('ivy-modal-open');
        $('#successModal').addClass('ivy-show');
        window.dispatchEvent(successModalOpened);
        opened = 'successModal';
        IvyLog.l('Success Modal Opened');
    },

    
    closeModal: function()
    {
        IvyLog.l('Closing');
        if (opened) {
            let modalClosed = new CustomEvent('ivy-modal-closed', {detail: opened});
            $('#' + opened).removeClass('ivy-show');
            $('body').removeClass('ivy-modal-open');
            window.dispatchEvent(modalClosed);
            IvyLog.l('Modal Closed');
            opened = false;
        }

    },

    
    success: function()
    {
        window.dispatchEvent(paymentDone);
        paymentSuccess = true;
    },
    getCardType: function(card) {
        var amex = /^37/;
        var visa = /^4/;
        var master = /^5(1|2|3|4|5)/;
        var discover = /^6011/;

        if (amex.test(card)) {
            return 'amex';
        } else if (visa.test(card)) {
            return 'visa';
        } else if (master.test(card)) {
            return 'master';
        } else if (discover.test(card)) {
            return 'discover';
        } else {
            return false;
        }
    }
    }
}());

export const IvyRender = (function() {
    return {
        render: function(template, selector, vars)
        {
            IvyRpc.Rpc("Common\\Common", 'render', {template: template, vars: vars}).then(response => {
                $(selector).replaceWith(response.result.html);
            });
        },
        renderMenu: function()
        {
            IvyRpc.Rpc("Common\\Common", 'render', {template: '/professionals/partial/navs/loged-in-menu.phtml'}).then(response => {
                $('body').removeClass('promo-bar');
                $('.ivy-header').replaceWith(response.result.html);
            })
        }
    }
}());
export const IvyRpc = (function() {
    return {
        Rpc: function(id, method, params, log) {
                id = "Api\\" + id;

                if (log == true) {
                    let params_string = JSON.stringify(params);
                    IvyLog.logMessage(`Call to RPC with params: Category: ${id}, Method: ${method}, Params: ${params_string}`);
                }
                let request_data = {
                    id: id,
                    method: method
                };
                if (params !== 'undefined') {
                    request_data.params = params;
                }
                return $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: '/application/rpc',
                    data: JSON.stringify(request_data),
                    complete(xhr, textStatus) {
                        if (xhr.status == 403) {
                            $.notify("You are not authorized to make this request", {
                                position: "top right",
                                autoHide: true
                            });
                        }
                        if (xhr.status == 401) {
                            $.notify("Session expired, reloading page", {position: "top right", autoHide: false});
                            setTimeout(() => location.reload(), 3000);
                        }
                    }
                });
        }
    }
}());