<template>
  <div class="row ps-md-2 row-cols-1 row-cols-md-3 g-4">
    <component
      :is="componentName"
      class="col"
      :key="index"
      v-for="(item, index) in getItems"
      :data="item"
      :index="index"
    />
  </div>
</template>

<script>
import MBlogCard from "@/components/molecules/MBlogCard";

export default {
  components: { MBlogCard },
  props: {
    items: Array,
    componentName: String,
    numberOfShow: Number
  },

  computed: {
    getItems: function () {
      return this.items.slice(0, this.numberOfShow);
    }
  }
}
</script>
