export const TESTEMONIAL_SLIDER_OPTIONS = {
  perPage: 3,
  gap: '1.5rem',
  type: 'loop',
  arrows: false,
  breakpoints: {
    '992': { perPage: 2, gap: '2rem' },
    '768': { perPage: 2, gap: '1rem' },
    '576': { perPage: 1 },
  }
}

export const UPCOMING_CLASSES_SLIDER_OPTIONS = {
  perPage: 1,
  arrows: false,
}
