export const TESTEMONIALS = [
    {
        comment: 'I found Ivy Exec excellent - punctual, professional and clear with delivery and expectations. Overall a genuinely enjoyable experience and look forward to working with them again.',
        author: 'Mark D.',
        date: 'March 25, 2021',
        from: 'thrustpilot',
        rating: 5,
    },
    {
        comment: 'Good, solid company, responsive to questions and inquiries.  Communication was excellent and I look forward to more surveys with them.',
        author: 'Tal A.',
        date: 'June 29, 2021',
        from: 'yelp',
        rating: 5,
    },
    {
        comment: 'Marketing survey was legit, professionally done, and payment was swift. Can\'t wait to do the next one.',
        author: 'Adrian A.',
        date: 'June 18, 2020',
        from: 'better-business',
        rating: 5,
    },
    {
        comment: 'The folks at Ivy Exec are always very helpful and responsive to my requests. Excellent customer service!!!!',
        author: 'Joseph K.',
        date: 'June 10, 2021',
        from: 'thrustpilot',
        rating: 5
    },
    {
        comment: 'Ivy Exec is a site with great job listings, and they also offer free resume consultations. If you are in an advanced stage in your career, getting an Ivy Exec account is a good idea.',
        author: 'Kaitlyn R.',
        date: 'September 15, 2021',
        from: 'google',
        rating: 5,
    },
    {
        comment: 'I like the choice of the many professional online classes and webinars.',
        author: 'Roland H.',
        date: 'July 16, 2020',
        from: 'better-business',
        rating: 5,
    },
    {
        comment: 'Very professional and seamless process. I\'d certainly appreciate the opportunity to work with Ivy Exec Inc. again!',
        author: 'N. H.',
        date: 'March 3, 2020',
        from: 'better-business',
        rating: 5,
    },
    {
        comment: 'The program offer is very useful and the customer service is great. I\'m happy to have Ivy Exec in my back pocket as I navigate my career moves.',
        author: 'Imani M.',
        date: 'March 2, 2021',
        from: 'yelp',
        rating: 5,
    },
    {
        comment: 'I found the Ivy Exec Consultant very professional and the advice given was extremely useful. I would strongly recommend Ivy Exec.',
        author: 'Colin L.',
        date: 'Jun 8, 2021',
        from: 'thrustpilot',
        rating: 5,
    },
    {
        comment: 'Fantastic company to work with!  Great people, professional and courteous.  I would highly recommend them!',
        author: 'Michelle S.',
        date: 'February 3, 2021',
        from: 'yelp',
        rating: 5,
    },
    {
        comment: 'It\'s a great platform to connect to participate on different online webinars as well many interesting articles of what is going on in the industry.',
        author: 'Carla J.',
        date: 'May 26, 2020',
        from: 'yelp',
        rating: 4,
    },
    {
        comment: 'Easy to work with and very thorough. It\'s obvious they know their stuff! Two thumbs up.',
        author: 'Jill K.',
        date: 'August 16, 2021',
        from: 'google',
        rating: 5,
    },
];
