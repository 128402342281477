export const JOBS = [
    {name: "Executive-Level", link: '/jobs/executive-jobs'},
    {name: "Top Companies for Compensation", link: '/companies-hiring?feature%5B%5D=Top+Companies+for+Compensation#'},
    {name: "Director", link: '/jobs/director-jobs'},
    {name: "Most Recent", link: '/jobs/latest-jobs'},
    {name: "Vice President", link: '/jobs/vice-president-jobs'},
    {name: "Best Reviewed Companies", link: '/companies-hiring?feature%5B%5D=Best+Reviewed+Companies# '},
    {name: "Companies Hiring", link: '/companies-hiring?feature%5B%5D=Companies+Hiring#'},
    {name: "Women Led Businesses", link: '/companies-hiring?feature%5B%5D=Women+Led+Businesses+to+Watch#'},
    {name: "Top Companies for Culture", link: '/companies-hiring?feature%5B%5D=Top+Companies+for+Culture# '}
];
