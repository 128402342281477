<template>
  <section class="row row-cols-1 row-cols-lg-2 t-job-search-frame">
    <div class="col ps-md-4 pe-md-0 order-1 order-lg-0">
      <img :src="require('@/assets/images/redesign-homepage/job-search-bg.jpg')" alt="Job Search Background">
    </div>
    <div class="col">
      <div class="t-job-search-frame-content">
        <h6> Job Search </h6>
        <h2 class="h1">
          Find <i>Hand Picked</i> Senior-Level Jobs
        </h2>
        <div class="t-job-search-frame-content-jobs">
          <a
            v-for="(job, index) in jobs"
            :href="job.link"
            :key="index"
            class="btn btn-light t-job-search-frame-content-jobs-job"
          >
            {{ job.name }}
          </a>
        </div>
        <a href="/jobs/latest-jobs" class="t-job-search-frame-content-jobs-btn d-block text-decoration-none">
          See All Jobs
          <a-icon icon="arrow-down" class="ms-md-3"/>
        </a>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    jobs: Array,
  }
}
</script>
