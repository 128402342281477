<template>
  <div class="m-testemonial-card h-100 d-flex flex-column rounded-2 mx-auto">
    <p class="mb-2"> {{ testemonial.comment }} </p>
    <div class="mt-auto">
      <div class="mb-2 mb-md-1">
        <a-icon
          icon="rating-star"
          v-for="(star, rating) in testemonial.rating"
          :key="rating"
          style="margin-right: 2.5px"
        />
      </div>

      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h5> {{ testemonial.author }} </h5>
          <span class="text-muted"> {{ testemonial.date }} </span>
        </div>
        <img :src="require(`@/assets/images/homepage/review-logos/${testemonial.from}.webp`)" alt="Logo">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    testemonial: {
      type: Object,
      required: true,
    }
  }
}
</script>
