<template>
  <main id="app" class="p-homepage">
    <script type="application/javascript" src="../../ivy-assets/dist/js/utils/Ivy.min.js"></script>
    <header class="header">
<!--      <img-->
<!--        class="img-fluid header-bg"-->
<!--        src="./assets/images/redesign-homepage/header-bg.jpg"-->
<!--        alt="Header image"-->
<!--      />-->
      <picture>
        <source
          media="(min-width:768px)"
          class="img-fluid header-bg"
          :srcset="require('./assets/images/redesign-homepage/header-bg.jpg')"
        >
        <img
          :src="require('./assets/images/redesign-homepage/header-mobile-bg.jpg')"
          class="img-fluid header-bg"
          alt="companies image"
        />
      </picture>
        <div class="header-content">
          <div class="container">
            <div class="row">
              <div class="col-12 px-md-4">
                <h1>
                  Your source for executive <br class="d-none d-sm-inline-block">
                  jobs, career support, and <br class="d-none d-sm-inline-block">
                  paid research engagements
                </h1>
                <form class="input-group"  v-on:keyup.enter="joinNow($event, emailEngagements)">
                  <input
                    v-model="emailEngagements"
                    style="padding: .7rem .85rem"
                    type="email"
                    class="form-control px-3"
                    placeholder="Your email"
                    aria-label="Your email"
                    aria-describedby="button-join-now-1"
                  />
                  <button
                    @click="joinNow($event, emailEngagements)"
                    type="submit"
                    style="padding: 0.66rem 1.6rem"
                    class="btn btn-secondary text-white"
                    id="button-join-now-1"
                    :disabled="!emailEngagements"
                  >
                    Join Now
                  </button>
                </form>
                <p class="subtitle-paragraph">4,240 executive jobs added in the last week!</p>
                <p class="header-info-text">Featured In</p>

                <picture>
                  <source
                    media="(min-width:768px)"
                    class="img-fluid"
                    :srcset="require('./assets/images/redesign-homepage/logos-bg.png')"
                  >
                  <img
                    :src="require('./assets/images/redesign-homepage/logos-mobile-bg.png')"
                    class="img-fluid mobile-bg"
                    alt="companies image"
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div class="container">
        <section class="expertise-frame">
          <div class="row row-cols-1 row-cols-md-2">
            <div class="col px-md-4">
              <div class="expertise-content">
                <a-icon icon="dollar"/>
                <h2>
                  Get paid to share your expertise
                </h2>
                <p>
                  We connect you with paid research engagements at top research companies, consulting firms, and businesses around the world.
                </p>
                <a href="/professionals/register?ref=homepage_mi&promo=MI30days" class="btn btn-primary"> Sign Up </a>
              </div>
            </div>
            <div class="col position-relative">
              <div class="img-wrapper">
                <img
                  class="img-fluid expertise-img"
                  src="./assets/images/homepage/expertise.jpg"
                  alt="expertise image"
                />
              </div>
              <div class="opacity-frame">
                <div class="linked-frame red-dot bg-light">
                  <div class="d-flex align-items-center">
                    <p class="linked-frame-title">
                      Your payment:
                    </p>
                  </div>
                  <h6 class="linked-frame-description">
                    Monday, June 8, 10:00 AM
                  </h6>
                  <h6 class="linked-frame-subtitle">
                    <span>1 x Interview</span>
                    <span style="letter-spacing: 0">$400.00</span>
                  </h6>
                  <h6 class="linked-frame-description">
                    Friday, June 12, 12:00 PM
                  </h6>
                  <h6 class="linked-frame-subtitle" style="margin-bottom: 16px">
                    <span>1 x Online Focus Group</span>
                    <span style="letter-spacing: 0">$250.00</span>
                  </h6>
                  <h6 class="linked-frame-subtotal">
                    <span>Subtotal</span>
                    <span style="letter-spacing: 0.5px">$650.00</span>
                  </h6>
                  <p class="linked-frame-total">
                    <span>Total</span>
                    <span style="letter-spacing: 1px">$650.00</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <t-job-search-section :jobs="jobs" />
      </div>

      <div class="bg-light">
        <div class="container">
          <t-people-are-getting-jobs/>
        </div>
      </div>

      <div class="container">
        <section class="upgrade-career-frame career-coaches">
          <div class="row">
            <div class="col-12 px-md-4">
              <h6>Your Job Search Partner</h6>
              <h2 class="px-3 px-md-0">
                Get the support you need to land your next role
              </h2>
            </div>
          </div>
          <div class="row text-center text-lg-left">
            <div class="col-lg-6 position-relative career-coaches-image">
              <img
                class="upgrade-career-frame-image"
                src="./assets/images/homepage/upgrade-career.jpg"
                alt="upgrade career"
              />
              <div class="coaches">
                <div class="opacity-frame">
                  <div class="linked-frame mb-3">
                    <p class="linked-frame-banner">
                      Coaches available for you
                    </p>
                    <div class="coach-wrapper">
                      <div class="coach-card">
                        <img
                          class="img-fluid"
                          src="./assets/images/homepage/mery-brigethon.jpg"
                          alt="Mery Brigethon"
                        />
                        <h6>Mery Brigethon</h6>
                        <p>Career Coach</p>
                      </div>
                      <div class="coach-card">
                        <img
                          class="img-fluid"
                          src="./assets/images/homepage/gary-pitt.jpg"
                          alt="Gary Pitt"
                        >
                        <h6>Gary Pitt</h6>
                        <p>Career Coach</p>
                      </div>
                    </div>
                    <div class="coach-wrapper mt-md-2 mt-xl-0">
                      <div class="coach-card mb-0">
                        <img
                          class="img-fluid"
                          src="./assets/images/redesign-homepage/danny-harris.jpg"
                          alt="Danny Harris"
                        >
                        <h6>Danny Harris</h6>
                        <p>Career Coach</p>
                      </div>
                      <div class="coach-card mb-0">
                        <img
                          class="img-fluid"
                          src="./assets/images/redesign-homepage/clair-doughson.jpg"
                          alt="Clair Doughson"
                        >
                        <h6>Clair Doughson</h6>
                        <p>Career Coach</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="upgrade-career-frame-content">
                <a-icon icon="coach"/>
                <h3>Elite Career Coaches</h3>
                <p>
                  Tackle your toughest career obstacles with help from our team of elite career coaches.
                  From resume rewrites to interview prep, leadership training, and more — we’ve got your back.
                </p>
                <a href="/professionals/register?return_url=/professionals/coaching"
                   class="btn btn-outline-primary">
                  Get a Coach
                </a>
              </div>
            </div>
          </div>
        </section>

        <section class="resume-optimization-frame">
          <div class="row">
            <div class="col-lg-6 ps-md-4 mb-lg-0 resume-optimization-frame-content">
              <a-icon icon="resume" class="ms-md-1"/>
              <h3>1-on-1 Resume Critique</h3>
              <p>
                Get personalized feedback from an Ivy Exec Career Advisor on how
                to improve your resume and create a document that gets noticed.
              </p>
              <a href="https://resume.ivyexec.com/resume-help/ " class="btn btn-outline-primary">
                <span>Connect Now</span>
              </a>
            </div>
            <div class="col-lg-6 expert-wrapper">
              <div class="expert-frames left">
                <img src="./assets/images/redesign-homepage/tommy-jefferson.jpg" alt="Tommy Jefferson">
              </div>
              <div class="expert-frames right">
                <img src="./assets/images/redesign-homepage/barbara-gladskov.jpg" alt="Barbara Gladskov">
              </div>
            </div>
          </div>
        </section>

        <section class="upgrade-career-frame linkedin-optimization">
          <div class="row">
            <div class="col-lg-6 order-lg-1 mb-md-5 mb-lg-0">
              <div class="linkedin-optimization-content">
                <a-icon icon="linkedin"/>
                <h3>LinkedIn Profile Optimization</h3>
                <p>
                  Unlock the power of LinkedIn with a LinkedIn profile critique session. Your Career Advisor
                  will give you direct and actionable advice on how to craft a profile that stands out.
                </p>
                <a href="https://resume.ivyexec.com/linkedin-critique/ " class="btn btn-outline-primary">
                  Get Started
                </a>
              </div>
            </div>
            <div class="col-lg-6 position-relative">
              <div class="image-wrapper">
                <img src="./assets/images/redesign-homepage/linkedin-bg.png" alt="LinkedIn-bg">
              </div>
              <div class="opacity-frame">
                <div class="linked-frame bg-light text-center" style="padding: 15px">
                  <div class="d-flex align-items-center">
                    <p class="profile-grade">
                      profile grade:
                    </p>
                    <p class="profile-grade-mark">5.0</p>
                    <div class="stars d-flex">
                      <a-icon icon="rating-star"/>
                      <a-icon icon="rating-star"/>
                      <a-icon icon="rating-star"/>
                      <a-icon icon="rating-star"/>
                      <a-icon icon="rating-star"/>
                    </div>
                  </div>
                  <div style="background: #027BB5; height: 42px; margin-top: 3px"></div>
                  <img
                    class="img-fluid"
                    style="width: 100%; max-width: 61px; margin-top: -30px"
                    src="./assets/images/homepage/susan-gilbert.png"
                    alt="susan gilbert"
                  >
                  <h6 style="margin-bottom: 8px;font-weight: 600;font-size: 12px;line-height: 24px;letter-spacing: -0.2px;">
                    Susan Gilbert
                  </h6>
                  <h6 style="font-weight: normal;font-size: 12px;line-height: 24px;letter-spacing: -0.2px; margin-bottom: 0">
                    Creative Producer at Rigter Studio
                  </h6>
                  <h6 style="margin-bottom: 0;font-weight: normal;font-size: 12px;line-height: 160%;color: #000000;opacity: 0.5;">
                    Cape Cod <span style="color: #027BB5">Contact info</span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="upgrade-career-frame hiring-data">
          <div class="row">
            <div class="col-lg-6 ps-md-4">
              <div class="hiring-data-content">
                <a-icon icon="briefcase"/>
                <h3>Exclusive Company and Hiring Data</h3>
                <p>
                  Find out who’s hiring and what their employees
                  have to say with our exclusive company and hiring data.
                </p>
                <a href="/companies-hiring?feature%5B%5D=Companies+Hiring# " class="btn btn-outline-primary">
                  Find Companies Hiring
                </a>
              </div>
            </div>
            <div class="col-lg-6 text-center text-lg-start position-relative">
              <img
                class="hiring-data-image"
                src="./assets/images/redesign-homepage/exclusive-company-bg.png"
                alt="upgrade career"
              >
              <div class="opacity-frame">
                <div class="linked-frame bg-light" style="padding: 16px">
                  <div class="d-flex align-items-start" style="border-bottom: 1px solid #d7d7d7;">
                    <img
                      src="./assets/images/redesign-homepage/keystone-logo.png"
                      alt="upgrade career"
                      class="company-logo"
                    >
                    <div class="d-flex flex-column text-start">
                      <h5>Keystone Strategy</h5>
                      <p>Boston, MA</p>
                      <div class="d-flex justify-content-start rate">
                        <a-icon icon="rating-star"/>
                        <a-icon icon="rating-star"/>
                        <a-icon icon="rating-star"/>
                        <a-icon icon="rating-star"/>
                        <a-icon icon="rating-star"/>
                      </div>
                    </div>
                  </div>
                  <div class="card-wrapper">
                    <div class="card-wrapper-first">
                      <span class="icons">
                        <a-icon icon="user-vip" style="width: 24px; height: 24px"/>
                      </span>
                      <div class="d-flex flex-column" style="margin: 0 14px 0 12px">
                        <p class="label">People</p>
                        <p class="value">87%</p>
                      </div>
                      <div class="img-wrapper">
                        <img
                          class="img-fluid"
                          src="./assets/images/homepage/red-chart.png"
                          alt="chart"
                        >
                      </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-evenly">
                      <span class="icons">
                        <a-icon icon="pie-chart" style="width: 24px; height: 24px"/>
                      </span>
                      <div class="d-flex flex-column" style="margin: 0 14px 0 12px">
                        <p class="label">Money</p>
                        <p class="value">94%</p>
                      </div>
                      <div class="img-wrapper">
                        <img
                          class="img-fluid"
                          src="./assets/images/homepage/gray-chart.png"
                          alt="chart"
                        >
                      </div>
                    </div>
                  </div>
                  <a href="/companies-hiring" class="show-more">
                    Show more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="upgrade-career-frame growth-courses">
          <div class="row">
            <div class="col-lg-6 order-lg-1">
              <div class="growth-courses-content">
                <a-icon icon="education"/>
                <h3>Career Growth Courses and Content</h3>
                <p>
                  Explore courses and content from New York Times best sellers, Nobel Prize winners,
                  and professors at top business school such as Wharton, Columbia, and Yale.
                </p>
                <a href="/professionals/classes" class="btn btn-outline-primary">
                  Join Now
                </a>
              </div>
            </div>
            <div class="col-lg-6 position-relative">
              <img
                class="growth-courses-image"
                src="./assets/images/redesign-homepage/career-growth-bg.png"
                alt="upgrade career"
              >
              <div class="opacity-frame">
                <div class="linked-frame bg-light">
                  <div class="top-section">
                    <p class="date">
                      May 29, 2022 12:00 PM - 1:00 PM
                    </p>
                    <p class="title">
                      Successful Career Transitions
                    </p>
                    <h6>Hank Riley - Executive Director for Experienced Professionals</h6>
                  </div>
                </div>

                <img
                  class="img-fluid"
                  style=""
                  src="./assets/images/homepage/professional-card.jpg"
                  alt="upgrade career"
                >
                <div class="linked-frame bottom bg-light mb-0">
                  <div class="bottom-section">
                    <p class="date">JUN 18, 2022 2:00 PM - 3:00 PM</p>
                    <p class="title">Making of a Modern Resume: 3 Trends to Avoid, and 3 to Use</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div class="container">
        <section>
          <div class="row">
            <div class="col-12 ps-md-4">
              <div class="t-ivy-comunity">
                <div class="t-ivy-comunity-content">
                  <h5>
                    Ivy Exec is an elite, <br class="d-sm-none">
                    global community of <br class="d-md-none">
                    highly successful professionals
                  </h5>
                  <div class="info-wrapper">
                    <div class="info-wrapper-card">
                      <a-icon icon="user-boxed"/>
                      <h6>2.5M+</h6>
                      <p>Members</p>
                    </div>
                    <div class="info-wrapper-card ps-md-4">
                      <a-icon icon="growth-scale"/>
                      <h6>80%</h6>
                      <p>Director and Above</p>
                    </div>
                    <div class="info-wrapper-card mb-0">
                      <a-icon icon="star-circle" class="mt-1"/>
                      <h6>500K+</h6>
                      <p>CXO Members</p>
                    </div>
                    <div class="info-wrapper-card mb-0 ps-md-4">
                      <a-icon icon="female-sign" class="mt-2"/>
                      <h6>800K+</h6>
                      <p>Women in Business</p>
                    </div>
                  </div>
                </div>
                <div class="t-ivy-comunity-image">
                  <img
                    src="./assets/images/redesign-homepage/professionals-bg.webp"
                    alt="background"
                    class="img-fluid"
                  >
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div class="t-join-now row">
        <img :src="require('./assets/images/homepage/join-now.jpg')" alt="join image"/>
        <div class="col position-relative">
          <div class="t-join-now-content">
            <h2>Join for free to access senior level jobs, paid research engagements, and much more</h2>
            <form class="input-group" v-on:keyup.enter="joinNow($event, emailFreeAccess)">
              <input
                v-model="emailFreeAccess"
                type="email"
                class="form-control"
                placeholder="Your email"
                aria-label="Your email"
                aria-describedby="button-join-now"
              />
              <button type="submit" @click="joinNow($event, emailFreeAccess)" class="btn btn-secondary text-white" id="button-join-now" :disabled="!emailFreeAccess">
                Join Now
              </button>
            </form>
          </div>
        </div>
      </div>

      <div class="bg-light">
        <div class="container">
          <t-upcoming-live-classes
            :upcomingClasses="upcomingClasses"
          />

          <section class="row career-advice">
            <div class="col-12 ps-md-4 a-title-above-frame">
              <h2 class="a-title-above mb-4 mb-md-0">
                Latest Career Advice
              </h2>
              <a href="/career-advice" class="show-more">
                Show more
                <a-icon icon="arrow-right"/>
              </a>
            </div>
            <div class="col-12">
              <t-items-preview
                :items="blogs"
                componentName="m-blog-card"
                :numberOfShow="3"
              />
            </div>
          </section>
        </div>
      </div>

<!--      <div class="t-join-now resume-critique-section row">-->
<!--        <div class="col position-relative">-->
<!--          <div class="wrapper">-->
<!--            <h2>-->
<!--              Get a Resume Critique and Access <br class="d-none d-xl-inline">-->
<!--              to Our Curated Job Board-->
<!--            </h2>-->
<!--            <form class="input-group" v-on:keyup.enter="joinNow($event, emailResume)">-->
<!--                <input-->
<!--                  type="email"-->
<!--                  class="form-control"-->
<!--                  placeholder="Your email"-->
<!--                  aria-label="Your email"-->
<!--                  aria-describedby="button-join-now2"-->
<!--                  required-->
<!--                  v-model="emailResume"-->
<!--                />-->
<!--                <button type="submit" @click="joinNow($event, emailResume)" class="btn btn-secondary text-white" id="button-join-now2" :disabled="!emailResume">-->
<!--                  Join Now-->
<!--                </button>-->
<!--            </form>-->
<!--            <p class="quote d-md-none">-->
<!--              4,240 executive jobs added in the <br> last week!-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </main>
</template>

<script>
import TPeopleAreGettingJobs from "@/components/templates/TPeopleAreGettingJobs";
import TJobSearchSection from "@/components/templates/TJobSearchSection";
import TUpcomingLiveClasses from "@/components/templates/TUpcomingLiveClasses";
import TItemsPreview from "@/components/templates/TItemsPreview";

import $ from 'jquery'
import { IvyRpc, IvyLog, IvyPayment, IvyRender } from "../../ivy-assets/dist/js/utils/Ivy";
import { JOBS } from "@/constants/jobs";

export default {
  name: 'App',

  components: {
    TPeopleAreGettingJobs,
    TJobSearchSection,
    TUpcomingLiveClasses,
    TItemsPreview,
  },

  data() {
    return {
      jobs: JOBS,
      upcomingClasses: [],
      blogs: [],
      emailResume: '',
      emailEngagements: '',
      emailFreeAccess: ''
    }
  },

  mounted() {
    this.getBlogs();
    this.getWebinars();
  },

  methods: {
    joinNow(e, email) {
      e.preventDefault();
      const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

      if(email.toLowerCase().match(regex)) {
        return window.location.href = `/professionals/register?email=${email}`;
      }

      alert('Invalid Email');
      // return false;
    },

    getBlogs() {
      const miB2Bcategories = '14437,14535,14534'; // to be updated with dynamic value
      const perPage = 3;
      fetch(`https://www.ivyexec.com/career-advice/wp-json/wp/v2/posts?_embed&categories_exclude=${miB2Bcategories}&per_page=${perPage}`)
        .then(response => response.json())
        .then(data => { this.blogs = data })
    },

    async getWebinars() {
      const response = await IvyRpc.Rpc('Webinar\\Webinar', 'getHomepageWebinars');
      this.upcomingClasses = response.result.webinars;
    }
  }
}
</script>
