import Vue from 'vue';
import App from './App.vue';
import 'bootstrap/dist/js/bootstrap.min';
import './assets/scss/main.scss';
import AIcon from "@/components/atoms/AIcon";
import VueSplide from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import VueMoment from 'vue-moment/vue-moment';

// global components
Vue.component('a-icon', AIcon);
Vue.use(VueSplide);

Vue.use(VueMoment);

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
}).$mount('#app');
