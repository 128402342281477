<template>
  <section class="row t-upcoming-classes-frame">
    <div class="col-12 ps-md-4 a-title-above-frame">
      <h2 class="a-title-above mb-4 mb-md-0">
        Upcoming Live Classes
      </h2>
      <a href="/professionals/classes" class="show-more">
        Show more
        <a-icon icon="arrow-right"/>
      </a>
    </div>
    <div class="col-12">
      <splide :slides="upcomingClasses" :options="sliderOptions">
        <splide-slide
          :id="upcomingClasses.length"
          :key="index"
          v-for="(classItem, index) in upcomingClasses"
        >
          <div class="row">
            <div class="col-12 d-flex flex-wrap flex-md-nowrap">
              <img
                class="slider-image"
                :src="`https://www.ivyexec.com/files/presenter_photo/${classItem.image}`"
                alt="Presenter Image"
              />
              <div class="slider-body">
                <div class="date-time d-flex flex-wrap">
                  <h6 class="d-flex align-items-center" style="margin-right: 9px">
                    <a-icon icon="calendar" class="me-2 me-xl-3"/>
                    <span  style="margin-top: 3px">{{ classItem.webinar_date | moment("MMMM D, YYYY") }}</span>
                  </h6>
                  <h6 class="d-flex align-items-center">
                    <a-icon icon="clock" class="me-2 m-xl-3"/>
                    <span  style="margin-top: 3px">{{ classItem.time_start }} - {{ classItem.time_end }} EST</span>
                  </h6>
                </div>
                <h3>{{ classItem.title }}</h3>
                <p>
                  <span>{{ classItem.author }},</span>
                  {{ classItem.author_title }}
                </p>

                <div class="buttons">
                  <a :href="`/professionals/classes/details/${classItem.link}`" class="btn btn-primary d-flex align-items-center justify-content-center">
                    Sign Up
                  </a>
                </div>
              </div>
            </div>
          </div>
        </splide-slide>
      </splide>
    </div>
  </section>
</template>

<script>
import { UPCOMING_CLASSES_SLIDER_OPTIONS } from "@/constants/splideOptions";

export default {
  props: {
    upcomingClasses: Array,
  },

  data() {
    return {
      sliderOptions: UPCOMING_CLASSES_SLIDER_OPTIONS,
    };
  }
};
</script>
